@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');

.header {
  text-align: center;
  font-weight: 200;
  font-size: 3rem;
  font-family: 'Open Sans', sans-serif;
}

.reviews {
  margin-top: 5%;
}

.blogSample {
  text-align: center;
  padding: 15px;
  letter-spacing: 2px;
  font-weight: 200;
  cursor: pointer;

}

.reviews {
  margin-bottom: 10%;
}
.blogTitle {
  margin-top: 4%;
  font-family: 'Merriweather', serif;
}

.blogImg {
  border-radius: 5px;
  border: solid 3px;
}

.blogcontent {
  padding-left: 11px;
  padding-right: 11px;
  font-size: 20px;
  font-weight: 200;
  line-height: 210%;
  font-family: 'Open Sans', sans-serif;
}

.blogaddn {
  font-family: 'Open Sans', sans-serif;
  margin-top: 3%;
  font-weight: 100;
}

.makeStyles-paper-6 {
  transition: box-shadow .3s;
}

.makeStyles-paper-6:hover {
  box-shadow: 0 0 20px rgba(96,91,177,.2);
}

.modal-content {
  text-align: center;
}

.modal {
  padding-left: 0 !important;
}

.modal-header {
  border-bottom: 0 none !important;
}

.modal-footer {
  border-top: 0 none;
}